@import '../../styles/functions.scss';

.component {
  position: relative;
  cursor: pointer;
}

.component > .overlay {
  display: none;
}

.component:not(.noHover):hover,
.component.show {
  transform: scale(1.2, 1.2);
}

.component:not(.noHover):hover > .overlay,
.component.show > .overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
}

.name {
  font-size: calc(18px / 1.2);
  line-height: 1.5em;
  color: var(--color-text-400);
}

.title {
  font-size: calc(16px / 1.2);
  line-height: 1.6em;
  color: var(--color-text-400);
}

@media (max-width: 1280px) {
  .name {
    font-size: linear-interpolation(calc(12px / 1.2), calc(18px / 1.2), 375px, 1280px);
  }

  .title {
    font-size: linear-interpolation(calc(10px / 1.2), calc(16px / 1.2), 375px, 1280px);
  }
}
