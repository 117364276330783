.header {
  margin-top: 64px;
  width: clamp(852px, 940px - (1920px - 100vw) / (1920 - 1280) * 88, 940px);
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content;
  grid-template-areas: 'logo nav';
  align-items: center;
}

.logo {
  grid-area: logo;
  margin-left: 64px;
  margin-right: clamp(48px, 72px - (1920px - 100vw) / (1920 - 1280) * 24, 72px);
  width: 200px;
  height: 100px;
}

.nav {
  grid-area: nav;
}

.nav ul {
  display: flex;
}

.nav li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav li + li {
  margin-left: clamp(40px, 56px - (1920px - 100vw) / (1920 - 1280) * 16, 56px);
}

.nav a {
  font-size: 1rem;
  line-height: 1.8em;
  letter-spacing: 0.15px;
  color: var(--color-text-100);
}

.szechenyi {
  grid-area: szechenyi;
}

.menuButton {
  display: none;
}

@media (max-width: 1280px) {
  .header {
    margin-top: clamp(32px, 32px + (100vw - 800px) / (1280 - 800) * 28, 64px);
    width: clamp(583px, 852px - (1280px - 100vw) / (1280 - 800) * 269, 852px);
  }

  .logo {
    width: clamp(120px, 120px + (100vw - 800px) / (1280 - 800) * 80, 200px);
    height: clamp(60px, 60px + (100vw - 800px) / (1280 - 800) * 40, 100px);
    margin-left: clamp(40px, 64px - (1280px - 100vw) / (1280 - 800) * 24, 64px);
    margin-right: 48px;
  }

  .menuButton {
    justify-self: flex-end;
    align-self: center;
  }

  .nav li + li {
    margin-left: clamp(20px, 40px - (1280px - 100vw) / (1280 - 800) * 20, 40px);
  }
}

@media (max-width: 800px) {
  .header {
    margin-top: clamp(20px, 20px + (800px - 100vw) / (800 - 375) * 12, 32px);
    width: clamp(207px, 207px + (100vw - 375px) / (800 - 375) * 163, 370px);
  }

  .logo {
    width: clamp(120px, 120px + (100vw - 375px) / (800 - 375) * 80, 200px);
    height: clamp(60px, 60px + (100vw - 375px) / (800 - 375) * 40, 100px);
    margin-left: clamp(28px, 40px - (100vw - 375px) / (800 - 375) * 12, 40px);
    margin-right: 0;
  }

  .nav {
    position: absolute;
    top: clamp(81px, 81px + (100vw - 375px) / (800 - 375) * 31, 112px);
    left: 0;
    right: 0;
    padding-left: clamp(40px, 40px - (100vw - 375px) / (800 - 375) * 12, 40px);
    background-color: white;
    z-index: 10;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }

  .nav ul {
    flex-direction: column;
    margin-top: 40px;
  }

  .nav li {
    margin-bottom: 1.33rem;
    justify-content: flex-start;
  }

  .nav li + li {
    margin-left: 0;
  }

  .nav a {
    font-size: 1.33rem;
  }

  .menuButton {
    display: block;
  }

  .menuButton div {
    width: 2rem;
    height: 2px;
    background-color: var(--color-primary-100);
    transition: transform 0.5s;
  }

  .menuButton div:nth-child(2) {
    transition: opacity 0.5s;
  }

  .menuButton div + div {
    margin-top: 0.5rem;
  }

  .header.open .menuButton div:nth-child(1) {
    transform: translateY(calc(0.5rem + 2px)) rotate(45deg);
    transform-origin: center center;
    transition: transform 0.5s;
  }

  .header.open .menuButton div:nth-child(2) {
    opacity: 0;
    transition: transform 0.5s;
  }

  .header.open .menuButton div:nth-child(3) {
    transform: translateY(calc(-0.5rem - 2px)) rotate(-45deg);
    transform-origin: center center;
    transition: transform 0.5s;
  }

  .header.open .nav {
    max-height: 100vh;
    transition: max-height 1s;
  }

  .szechenyi {
    width: clamp(116px, 116px + (100vw - 375px) / (800 - 375) * 176, 292px);
    height: clamp(80px, 80px + (100vw - 375px) / (800 - 375) * 124, 204px);
  }
}
