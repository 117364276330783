@import '../../styles/functions.scss';

.component {
  display: grid;
  grid-template-columns: 6fr minmax(linear-interpolation(600px, 720px, 1280px, 1920px), 6fr);
  column-gap: 80px;
}

.interestedButton {
  margin-top: 60px;
}

@media (max-width: 1280px) {
  .component {
    display: block;
  }

  .imageBlock {
    margin-top: 40px;
    height: linear-interpolation(370px, 798px, 375px, 1280px);
  }
}

@media (min-width: 1281px) {
  .interestedButton {
    display: none;
  }
}
