@import '../../styles/functions.scss';

.explodedBlocks {
  display: flex;
  flex-direction: row;
}

.normalBlocks {
  display: none;
}

.red {
  --backgroundUrl: url(/images/forklift.png);
  --backgroundGradiend: linear-gradient(180deg, #9c0f0f 0%, rgba(153, 19, 19, 0) 100%);
  --iconBackgroundColor: #690909;

  margin-left: 28px;
  width: 360px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.orange {
  --backgroundUrl: url(/images/adhesive-tape.png);
  --backgroundGradiend: linear-gradient(360deg, #c88b03 0%, rgba(244, 169, 0, 0) 100%);
  --iconBackgroundColor: #7b5600;

  margin-left: 40px;
  width: 400px;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.blue {
  --backgroundUrl: url(/images/truck.png);
  --backgroundGradiend: linear-gradient(180deg, #0c5e8a 0%, rgba(35, 116, 159, 0) 100%);
  --iconBackgroundColor: #074363;

  margin-top: 40px;
  width: 360px;
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.green {
  --backgroundUrl: url(/images/call-center.png);
  --backgroundGradiend: linear-gradient(360deg, #758501 0%, rgba(180, 200, 34, 0) 100%);
  --iconBackgroundColor: #596500;

  margin-top: 40px;
  width: 440px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: 1800px) {
  .explodedBlocks {
    display: none;
  }

  .normalBlocks {
    display: grid;
    grid-template-columns: min-content min-content;
    grid-template-rows: auto;
    gap: 10px;
    justify-self: center;
  }

  .block {
    margin: 0;
    width: 295px;
    height: 295px;
  }

  .block:nth-child(2n) {
    margin-left: auto;
  }
}

@media (max-width: 1280px) {
  .red,
  .orange,
  .blue,
  .green {
    position: static;
    width: 295px;
    height: 295px;
  }

  .interestedButton {
    margin-top: 40px;
  }
}

@media (min-width: 1281px) {
  .interestedButton {
    display: none;
  }
}

@media (max-width: 675px) {
  .normalBlocks {
    display: grid;
    grid-template-columns: min-content;
    grid-template-rows: auto;
    row-gap: 40px;
    justify-self: center;
  }
}
