@import '../../styles/functions.scss';

.component {
  margin-top: 60px;
}

.dots {
  width: linear-interpolation(32px, 72px, 800px, 1920px);
  height: linear-interpolation(32px, 72px, 800px, 1920px);
}

.label {
  margin-top: linear-interpolation(40px, 48px, 800px, 1920px);
  font-size: linear-interpolation(10px, 14px, 800px, 1920px);
  line-height: linear-interpolation(13px, 18px, 800px, 1920px);
  text-align: left;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: var(--color-text-200);
}

.title {
  margin-top: linear-interpolation(16px, 24px, 800px, 1920px);
  font-weight: bold;
  font-size: linear-interpolation(28px, 52px, 800px, 1920px);
  line-height: linear-interpolation(36px, 68px, 800px, 1920px);
  text-align: left;
  color: var(--color-primary-100);
}

.title + .content {
  margin-top: linear-interpolation(16px, 24px, 800px, 1920px);
}

.content {
  width: 95%;
  font-size: linear-interpolation(12px, 16px, 800px, 1920px);
  line-height: 1.8em;
  letter-spacing: 0.15px;
  color: var(--color-text-100);
}

.highlightedContent {
  width: 95%;
  margin-top: linear-interpolation(16px, 24px, 800px, 1920px);
  font-weight: bold;
  font-size: linear-interpolation(12px, 16px, 800px, 1920px);
  line-height: 1.5em;
  letter-spacing: 0.15px;
  color: var(--color-text-100);
}

.highlightedContent + .list {
  margin-top: linear-interpolation(16px, 24px, 800px, 1920px);
}

.list {
  width: 95%;
  list-style-type: none;
  list-style-position: outside;
}

.list > li {
  font-size: linear-interpolation(12px, 16px, 800px, 1920px);
  line-height: 1.8em;
  letter-spacing: 0.15px;
  display: flex;
}

.list > li:before {
  display: inline-block;
  content: '';
  background-image: url(/images/bullet-point.svg);
  background-position: center center;
  width: 16px;
  min-width: 16px;
  height: 1.8em;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 16px;
}

.list > li + li {
  margin-top: 8px;
}

.interestedButton {
  margin-top: 48px;
}

@media (max-width: 1800px) {
  .component {
    margin-top: 0;
  }
}

@media (max-width: 1280px) {
  .interestedButton {
    display: none;
  }
}

@media (max-width: 800px) {
  .content {
    font-size: linear-interpolation(12px, 16px, 375px, 800px);
  }

  .highlightedContent {
    margin-top: linear-interpolation(16px, 24px, 375px, 800px);
    font-size: linear-interpolation(12px, 16px, 375px, 800px);
  }

  .highlightedContent + .list {
    margin-top: linear-interpolation(16px, 24px, 375px, 800px);
  }

  .list > li {
    font-size: linear-interpolation(12px, 16px, 375px, 800px);
  }
}
