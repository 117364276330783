@import '../../styles/functions.scss';

.component {
  margin-top: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
  margin-bottom: linear-interpolation(280px, 600px, 1280px, 1920px);
}

@media (max-width: 1280px) {
  .component {
    margin-top: linear-interpolation(42px, 70px, 700px, 1280px);
    margin-bottom: linear-interpolation(80px, 99px, 700px, 1280px);
  }
}

@media (max-width: 700px) {
  .component {
    margin-top: 40px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100vw;
    margin-left: calc(-1 * var(--padding-x));
    margin-bottom: 80px;
  }
}
