@import '../../styles/functions.scss';

.component {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  height: 1000px;
  z-index: -2;
}

.heroImage {
  position: absolute;
  right: linear-interpolation(-180px, 0px, 1280px, 1920px);
  width: 42%;
  border-radius: 0 0 0 120px;
}

@media (max-width: 1280px) {
  .component {
    display: none;
  }
}
