@import '../../styles/functions.scss';

.component {
  display: flex;
  align-items: center;
  margin-top: linear-interpolation(33px, 48px, 375px, 1920px);
}

.icon {
  width: 16px;
  height: 16px;
}

.text {
  font-size: linear-interpolation(12px, 14px, 375px, 1920px);
  margin-left: 9px;
}
