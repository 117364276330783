@import '../../styles/functions.scss';

.component {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: var(--backgroundGradiend), var(--backgroundUrl);
  padding: linear-interpolation(32px, 40px, 800px, 1920px);
  border-radius: 40px;
}

.icon {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: var(--color-text-400);
  background-color: var(--iconBackgroundColor);
}

.title {
  font-size: 36px;
  line-height: 47px;
  font-weight: 500;
  color: var(--color-text-400);
  margin-top: 16px;
  margin-bottom: 8px;
}

.text {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: var(--color-text-400);
}

.cta {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.15px;
  color: var(--color-text-400);
  margin-top: 30px;
}

@media (max-width: 1800px) {
  .component {
    padding: 32px;
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .title {
    font-size: 28px;
    line-height: 36px;
  }
}
