@import '../../styles/functions.scss';

.component {
  direction: ltr;
}

.title {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: right;
  color: var(--color-primary-100);
  cursor: pointer;
}

.passiveTitle {
  color: var(--color-text-200-opacity-50);
}

.title::after {
  position: absolute;
  top: 9px;
  right: -27px;
  content: '';
  height: 20px;
  width: 20px;
  background-image: url(/images/bullet-point-circle.svg);
}

.title + .content {
  margin-top: 16px;
}

.content {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  text-align: right;
}

@media (max-width: 800px) {
  .title {
    text-align: left;
    font-size: linear-interpolation(20px, 24px, 375px, 800px);
  }

  .title::after {
    left: -27px;
  }

  .content {
    text-align: left;
    font-size: linear-interpolation(12px, 16px, 375px, 800px);
  }
}

.base {
  // transition: max-height 450ms;
  overflow: hidden;
}

.isOpen {
  max-height: 100vh;
  opacity: 100%;
  transform: translateY(0);
}

.isClosed {
  opacity: 0;
  max-height: 0;
  transform: translateY(60px);
}
