@import '../../styles/functions.scss';

.szechenyi {
  margin-top: calc(-1 * linear-interpolation(143px, 250px, 1280px, 1920px));
  margin-left: auto;
  width: linear-interpolation(210px, 361px, 1280px, 1920px);
  height: linear-interpolation(143px, 250px, 1280px, 1920px);
  z-index: 10;
}

@media (max-width: 1280px) {
  .szechenyi {
    margin-top: calc(-1 * linear-interpolation(100px, 143px, 800px, 1280px));
    width: linear-interpolation(143px, 210px, 800px, 1280px);
    height: linear-interpolation(100px, 143px, 800px, 1280px);
  }
}

@media (max-width: 800px) {
  .szechenyi {
    margin-top: calc(-1 * linear-interpolation(80px, 100px, 375px, 800px));
    width: linear-interpolation(116px, 143px, 375px, 800px);
    height: linear-interpolation(80px, 100px, 375px, 800px);
  }
}
