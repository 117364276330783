@import '../../styles/functions.scss';

.component {
  position: fixed;
  bottom: linear-interpolation(190px, 340px, 1280px, 1920px);
  right: calc(var(--padding-x) / 4);
  width: 80px;
  height: 80px;
  padding: 26px 28px 28px 28px;
  background-color: var(--color-primary-500);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.component:hover {
  opacity: 0.7;
}

.component:active {
  opacity: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(0deg, var(--color-primary-500), var(--color-primary-500));
}

@media (max-width: 1280px) {
  .component {
    bottom: linear-interpolation(130px, 190px, 800px, 1280px);
  }
}

@media (max-width: 800px) {
  .component {
    bottom: linear-interpolation(110px, 130px, 375px, 800px);
  }
}
