@import '../../styles/functions.scss';

.dotsContainer {
  display: flex;
  justify-content: flex-end;
}

.dots {
  width: linear-interpolation(32px, 72px, 800px, 1920px);
  height: linear-interpolation(32px, 72px, 800px, 1920px);
}

.label {
  margin-top: linear-interpolation(30px, 48px, 800px, 1920px);
  font-size: linear-interpolation(10px, 12px, 800px, 1920px);
  text-align: right;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: var(--color-text-200);
}

.title {
  margin-top: linear-interpolation(15px, 24px, 800px, 1920px);
  font-weight: bold;
  font-size: linear-interpolation(28px, 52px, 800px, 1920px);
  line-height: 1.3em;
  text-align: right;
  color: var(--color-primary-100);
}

.introduction {
  margin-top: linear-interpolation(15px, 24px, 800px, 1920px);
  font-size: 1rem;
  line-height: 1.8em;
  text-align: right;
  color: var(--color-text-100);
}

.interestedButtonContainer {
  margin-top: linear-interpolation(30px, 48px, 800px, 1920px);
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 800px) {
  .dotsContainer {
    justify-content: flex-start;
  }

  .title {
    text-align: left;
  }

  .label {
    text-align: left;
  }

  .introduction {
    text-align: left;
  }

  .interestedButtonContainer {
    display: none;
  }
}
