@import '../../styles/functions.scss';

.component {
  margin-top: linear-interpolation(80px, 300px, 1280px, 1920px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'boxyContact furtherQuestions';
}

.furtherQuestions {
  grid-area: 'furtherQuestions';
}

.boxyContact {
  grid-area: boxyContact;
  position: relative;
  align-self: center;
}

@media (max-width: 800px) {
  .component {
    margin-top: 80px;
    margin-bottom: 0;
    display: block;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'furtherQuestions' 'boxyContact';
  }
}
