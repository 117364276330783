.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  min-width: 11.25rem;
  border-radius: 2rem;
  border-width: 0;
  padding: 0 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: 1px;
  color: var(--primary-button-color);
  background: var(--primary-button-background);
  filter: var(--button-shadow);
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
}

.button:hover:not([disabled]) {
  opacity: 0.7;
}

.button:active {
  opacity: 1;
  background: var(--primary-button-active-background);
}

.button:disabled {
  background: var(--primary-button-disabled-background);
}
