@import '../../styles/functions.scss';

.component {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: grid;
}

.boxes {
  grid-area: grid;
  margin-left: linear-interpolation(-200px, -260px, 800px, 1920px);
  margin-top: linear-interpolation(-28px, -47px, 800px, 1920px);
  width: linear-interpolation(600px, 1080px, 800px, 1920px);
}

.webshops {
  grid-area: grid;
  margin-top: linear-interpolation(100px, 120px, 800px, 1920px);
  width: linear-interpolation(293px, 635px, 800px, 1920px);
}

@media (max-width: 800px) {
  .boxes {
    grid-area: grid;
    margin-left: -80px;
    margin-top: 0;
    width: linear-interpolation(450px, 850px, 375px, 800px);
  }

  .webshops {
    margin-top: linear-interpolation(60px, 90px, 375px, 800px);
    width: linear-interpolation(300px, 600px, 375px, 800px);
  }
}

@media (min-width: 801px) {
  .furtherInfoButtonContainer {
    display: none;
  }
}
