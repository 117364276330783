.component {
  margin-top: 48px;
}

.dottedLine {
  margin-right: 33px;
}

.dottedLine > li {
  position: relative;
  padding-bottom: 64px;
}

.dottedLine > li:last-of-type {
  padding-bottom: 0;
}

.dottedLine > li:after {
  position: absolute;
  top: 10px;
  right: -18px;
  content: '';
  border-left: 2px dotted var(--color-primary-200);
  height: 100%;
  z-index: -1;
}

.dottedLine > li:last-of-type:after {
  display: none;
}

@media (max-width: 800px) {
  .dottedLine {
    margin-left: 33px;
  }

  .dottedLine > li:after {
    left: -18px;
  }
}