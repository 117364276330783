.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  border-radius: 2rem;
  border-width: 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: 1px;
  color: var(--separated-icon-button-color);
  background: var(--separated-icon-button-background);
  filter: var(--button-shadow);
  margin-left: 6px;
}

.iconContainer {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -6px;
  width: 3rem;
  height: 3rem;
  background-color: var(--separated-icon-button-icon-background);
  color: var(--separated-icon-button-icon-color);
  z-index: 1;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
}

.label {
  margin: 0 1.5rem;
}

.button:hover {
  opacity: 0.7;
}

.button:active {
  opacity: 1;
}

.button:active .iconContainer {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(0deg, var(--color-primary-500), var(--color-primary-500));
}

.button:disabled {
  color: var(--separated-icon-button-disabled-color);
  opacity: 1;
}

.button:disabled .iconContainer {
  background: var(--separated-icon-button-disabled-icon-background);
}
