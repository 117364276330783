@import '../../styles/functions.scss';

.component {
  display: grid;
  grid-template-columns: 5fr 7fr;
  grid-template-rows: 1fr;
  margin-bottom: linear-interpolation(80px, 405px, 800px, 1920px);
}

@media (max-width: 1800px) {
  .component {
    grid-template-columns: 1fr min-content;
    grid-gap: 10px;
  }
}

@media (max-width: 1280px) {
  .component {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 40px;
  }
}
