@import '../../styles/functions.scss';

.component {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: grid;
}

.boxes {
  grid-area: grid;
  margin-left: linear-interpolation(-210px, -330px, 800px, 1920px);
  width: linear-interpolation(560px, 824px, 800px, 1920px);
}

.intro {
  grid-area: grid;
  margin-top: linear-interpolation(50px, 152px, 800px, 1920px);
  max-width: linear-interpolation(239px, 391px, 800px, 1920px);
  height: fit-content;
  background-image: url('/images/quotation-mark.svg');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 22.5%;
}

.introHeader {
  display: flex;
  align-items: center;
}

.avatar {
  width: linear-interpolation(80px, 120px, 800px, 1920px);
  height: linear-interpolation(80px, 120px, 800px, 1920px);
  min-width: 80px;
}

.headerText {
  margin-left: linear-interpolation(16px, 40px, 800px, 1920px);
}

.name {
  font-size: linear-interpolation(16px, 18px, 800px, 1920px);
  line-height: 1.5em;
  color: var(--color-text-100);
  font-weight: 700;
}

.ceoTitle {
  font-size: linear-interpolation(10px, 14px, 800px, 1920px);
  line-height: 1.5em;
  color: var(--color-text-100);
}

.ceoIntroText {
  margin-top: linear-interpolation(30px, 58px, 800px, 1920px);
  font-size: 1rem;
  line-height: 1.8em;
  color: var(--color-text-100);
  text-align: left;
}

@media (max-width: 800px) {
  .boxes {
    grid-area: grid;
    margin-left: linear-interpolation(-230px, -140px, 375px, 800px);
    width: linear-interpolation(560px, 824px, 375px, 800px);
  }

  .intro {
    margin-top: linear-interpolation(50px, 152px, 375px, 800px);
    max-width: linear-interpolation(239px, 391px, 375px, 800px);
  }

  .name {
    font-size: 1rem;
  }

  .ceoTitle {
    font-size: 0.75rem;
  }
}

@media (min-width: 801px) {
  .interestedButtonContainer {
    display: none;
  }
}
