@import '../../styles/functions.scss';

.component {
  display: grid;
  grid-template-columns: 5fr 7fr;
  grid-template-rows: min-content;
  grid-template-areas: 'ceo-intro boxy-intro';
  column-gap: 5%;
  margin-bottom: linear-interpolation(80px, 260px, 800px, 1920px);
}

.boxyIntro {
  grid-area: boxy-intro;
}

.ceoIntro {
  grid-area: ceo-intro;
  align-self: center;
  max-width: 400px;
}

@media (max-width: 800px) {
  .component {
    display: flex;
    flex-direction: column;
  }

  .ceoIntro {
    align-self: auto;
  }
}
