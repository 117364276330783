.component {
  margin-top: 100px;
}

.dotsContainer {
  display: flex;
  justify-content: flex-end;
}

.dots {
  width: clamp(32px, 32px + (100vw - 800px) / (1920 - 800) * 40, 72px);
  height: clamp(32px, 32px + (100vw - 800px) / (1920 - 800) * 40, 72px);
}

.label {
  margin-top: clamp(30px, 30px + (100vw - 800px) / (1920 - 800) * 18, 48px);
  font-size: clamp(10px, 10px + (100vw - 800px) / (1920 - 800) * 2, 12px);
  text-align: right;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: var(--color-text-200);
}

.furtherQuestions {
  margin-top: clamp(15px, 15px + (100vw - 800px) / (1920 - 800) * 9, 24px);
  font-weight: bold;
  font-size: clamp(28px, 28px + (100vw - 800px) / (1920 - 800) * 24, 52px);
  line-height: 1.3em;
  text-align: right;
  color: var(--color-primary-100);
}

.interestedButton {
  margin-top: 48px;
}

.buttoncontainer {
  display: flex;
  justify-content: flex-end;
}

.askForMoreContainer {
  display: flex;
  justify-content: flex-end;
}

.askForMore {
  margin-top: clamp(15px, 15px + (100vw - 800px) / (1920 - 800) * 9, 24px);
  font-size: 1rem;
  line-height: 1.8em;
  text-align: right;
  color: var(--color-text-100);
  max-width: 375px;
}

@media (max-width: 800px) {
  .component {
    margin-top: 0;
  }

  .dotsContainer {
    justify-content: flex-start;
  }

  .askForMoreContainer {
    justify-content: flex-start;
  }

  .buttoncontainer {
    justify-content: flex-start;
  }

  .furtherQuestions {
    text-align: left;
  }

  .label {
    text-align: left;
  }

  .askForMore {
    text-align: left;
    max-width: none;
  }
}
