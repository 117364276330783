@import '../../styles/functions.scss';

.component {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  grid-template-areas: grid;
  color: var(--color-footer-contact);
  width: clamp(271px, 271px + (100vw - 800px) / (1920 - 800) * 182, 453px);
}

.boxes {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-column-end: 2;
  position: relative;
  z-index: -1;
  left: linear-interpolation(-280px, -260px, 800px, 1920px);
  width: linear-interpolation(700px, 1073px, 800px, 1920px);
  height: linear-interpolation(608px, 887px, 800px, 1920px);
}

.content {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-column-end: 2;
  margin-top: linear-interpolation(155px, 195px, 800px, 1920px);
  width: linear-interpolation(320px, 453px, 800px, 1920px);
}

.boxylogowhite {
  width: clamp(120px, 120px + (100vw - 375px) / (1920 - 375) * 80, 200px);
  height: clamp(60px, 60px + (100vw - 375px) / (1920 - 375) * 40, 100px);
}

.contact {
  margin-top: 41px;
}

.pressroom {
  margin-top: 64px;
}

.boldtext {
  font-weight: 700;
  font-size: 1rem;
  line-height: 20.83px;
  letter-spacing: 0.15px;
}

.separatorline {
  height: 1px;
  opacity: 0.5;
  background: #ffffff;
  margin-top: 74px;
}

.allrightsreserved {
  color: var(--color-footer-allrightsreserved);
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: 24px;
}

@media (max-width: 800px) {
  .component {
    margin-top: 80px;
  }

  .content {
    margin-top: linear-interpolation(85px, 195px, 375px, 800px);
    width: linear-interpolation(250px, 453px, 375px, 800px);
  }

  .allrightsreserved {
    font-size: 10px;
  }

  .boxes {
    left: linear-interpolation(-280px, -260px, 375px, 800px);
    width: linear-interpolation(620px, 900px, 375px, 800px);
    height: linear-interpolation(512px, 743px, 375px, 800px);
  }
}
