@import '../../styles/functions.scss';

.component {
  display: grid;
  grid-template-columns: 5fr 7fr;
  grid-template-rows: min-content;
  grid-template-areas: 'webshops integrationsInfo';
  min-height: 100vh;
  margin-bottom: linear-interpolation(80px, 300px, 1280px, 1920px);
}

.integrationsInfo {
  grid-area: integrationsInfo;
}

.webshops {
  grid-area: webshops;
  align-self: flex-start;
}

@media (max-width: 1280px) {
  .component {
    margin-bottom: 80px;
  }
}

@media (max-width: 800px) {
  .component {
    display: flex;
    flex-direction: column;
  }
}
